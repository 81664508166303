import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { TiggbeeContactUs } from '../components/tiggbee-contact-us';
import { CareerPortalPageContent } from '../components/careerportalpage-content';
import { BannerSimple } from '../components/banner-simple';

const CareerPortalPage = () => (
    <div className='career-portal-container-page'>
      <Layout>
          <SEO title="Career Portal" />
          <BannerSimple
            message='Explore the vast career opportunties waiting for you.'
          />
          <CareerPortalPageContent />
          <TiggbeeContactUs
            titleElement={<>Our Mission is to empower our students regardless of economic or geographic barriers with the hope and knowledge of the opportunities to be happy and successful individuals.</>}
          />
      </Layout>
    </div>
)

export default CareerPortalPage
