import React from "react"
import "./content.css"
import "./careerportalpage-content.css"
import { BaseService } from "../services/base-service"

export class CareerPortalPageContent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
      isLoading: true,
      link: null, // "https://www.bls.gov/ooh/",
    }
  }

  componentDidMount() {
    this.fetchCareerData()
  }

  fetchCareerData = () => {
    const currentYearAndWeek = this.getCurrentYearAndWeek()
    const selectedOccupationId =
      ((currentYearAndWeek[0] - 2020) * 100 + currentYearAndWeek[1]) % 325

    fetch(`/data/careers/ooh/occupation-${selectedOccupationId}.json`)
      .then((response) => response.json())
      .then((data) => {
        data.image = data.image.replace(
          "/ooh/images/",
          "https://www.bls.gov/ooh/images/"
        )
        const url = data.url
        this.setState(
          {
            ...data,
            isLoading: false,
          },
          () => {
            if (url) {
              this.setState((state) => ({
                link: url,
              }))
            }
          }
        )
      })
      .catch((err) => {
        const error = err
        this.setState({
          error,
          // isLoading: false
        })
      })
  }

  getCurrentYearAndWeek = () => {
    //https://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php
    /* For a given date, get the ISO week number
     *
     * Based on information at:
     *
     *    http://www.merlyn.demon.co.uk/weekcalc.htm#WNR
     *
     * Algoritdm is to find nearest tdursday, it's year
     * is the year of the week number. then get weeks
     * between tdat date and the first day of tdat year.
     *
     * Note tdat dates in one year can be weeks of previous
     * or next year, overlap is up to 3 days.
     *
     * e.g. 2014/12/29 is Monday in week  1 of 2015
     *      2012/1/1   is Sunday in week 52 of 2011
     */
    function getWeekNumber(d) {
      // Copy date so don't modify original
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
      // Set to nearest tdursday: current date + 4 - current day number
      // Make Sunday's day number 7
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
      // Get first day of year
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
      // Calculate full weeks to nearest tdursday
      var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7)
      // Return array of year and week number
      return [d.getUTCFullYear(), weekNo]
    }
    return getWeekNumber(new Date())
  }

  setInfoContent = () => {
    const element = (
      <div className="container-fluid career-portal-content-contaner">
        <div className="row career-portal-content-row">
          <div className="col-md-6">
            <div className="career-portal-feature-div">
              <span>Featured Occupation</span>
            </div>
            <div
              id="CareerProtalImageDiv"
              className="career-portal-image-div"
              dangerouslySetInnerHTML={{ __html: this.state.image }}
            ></div>
          </div>
          <div className="col-md-6">
            <div className="career-portal-feature-div">
              {this.state.link ? (
                <a target="_blank" href={this.state.link}>
                  {this.state.title}
                </a>
              ) : (
                <a href="#">{this.state.title}</a>
              )}
              {/* <div>
                <span
                  onClick={() => {
                    console.log(this.state)
                  }}
                >
                  Check data
                </span>
              </div> */}
            </div>
            <table id="CareerProtalinfoTable">
              <thead>
                <tr className="career-portal-salary-table-tr">
                  <th>{this.state.referenceYear} Median Pay </th>
                  <th>
                    {BaseService.stringIsNullOrWhitespace(
                      this.state.medianPayAnnual
                    ) ? (
                      "N/A"
                    ) : (
                      <>
                        $
                        {BaseService.formatCurrency(
                          +this.state.medianPayAnnual
                        )}{" "}
                        per year
                      </>
                    )}
                    <div style={{ height: "9px" }}></div>
                    {BaseService.stringIsNullOrWhitespace(
                      this.state.medianPayHourly
                    ) ? (
                      "N/A"
                    ) : (
                      <>
                        $
                        {BaseService.formatCurrency(
                          +this.state.medianPayHourly
                        )}{" "}
                        per hour
                      </>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody className="career-portal-salary-table-tbody">
                <tr>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Typical Entry-Level Education </td>
                  <td>{this.state.entryLevelEducation}</td>
                </tr>
                <tr>
                  <td>Work Experience in a Related Occupation</td>
                  <td>{this.state.workExperience}</td>
                </tr>
                <tr>
                  <td>On-the-job Training</td>
                  <td>{this.state.onTheJobTraining}</td>
                </tr>
                <tr>
                  <td>Number of Jobs, 2016</td>
                  <td>
                    {BaseService.stringIsNullOrWhitespace(
                      this.state.numberOfJobs
                    ) ? (
                      "N/A"
                    ) : (
                      <>
                        {BaseService.formatCurrency(+this.state.numberOfJobs)}
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Job Outlook, 2016-26</td>
                  <td>
                    {this.state.jobOutlookPercent}% (
                    {this.state.jobOutlookDescription})
                  </td>
                </tr>
                <tr>
                  <td>Employment Change, 2016-26</td>
                  <td>
                    {BaseService.stringIsNullOrWhitespace(
                      this.state.employmentChange
                    ) ? (
                      "N/A"
                    ) : (
                      <>
                        {BaseService.formatCurrency(
                          +this.state.employmentChange
                        )}
                      </>
                    )}
                  </td>
                </tr>
                {/*  */}
                <tr>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <div className="career-portal-fromuslabor-div">
                      * U.S. Bureau of Labor
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
    return element
  }

  setLinkContent = () => {
    const element = (
      <>
        <div className="career-portal-link-container">
          <a
            className="career-portal-link-item-a"
            href="https://www.bls.gov/ooh/highest-paying.htm"
            target="_blank"
          >
            Find the Highest Paying
          </a>
          <img
            className="career-portal-link-arrow-img"
            alt=""
            src="/portal-career-arrow-1.svg"
          />
          <a
            className="career-portal-link-item-a"
            href="https://www.bls.gov/ooh/fastest-growing.htm"
            target="_blank"
          >
            Fastest Growing
          </a>
          <img
            className="career-portal-link-arrow-img"
            alt=""
            src="/portal-career-arrow-2.svg"
          />
          <a
            className="career-portal-link-item-a"
            href="https://www.bls.gov/ooh/most-new-jobs.htm"
            target="_blank"
          >
            Most New Jobs
          </a>
          <img
            className="career-portal-link-arrow-img"
            alt=""
            src="/portal-career-arrow-3.svg"
          />
          <a
            className="career-portal-button"
            href="https://www.bls.gov/ooh/"
            target="_blank"
          >
            Discover
          </a>
        </div>
      </>
    )
    return element
  }

  setMainContent = () => {
    if (this.state.isLoading === false) {
      setTimeout(() => {
        const CareerProtalImageDiv = document.getElementById(
          "CareerProtalImageDiv"
        )
        const CareerProtalinfoTable = document.getElementById(
          "CareerProtalinfoTable"
        )
        if (CareerProtalImageDiv && CareerProtalinfoTable) {
          const CareerProtalImageDiv_Image = CareerProtalImageDiv.children[0]
          const clientHeight = CareerProtalImageDiv_Image.clientHeight
          if (clientHeight) {
            CareerProtalinfoTable.style.minHeight = `${clientHeight}px`
          }
        }
      }, 500)
      const content = (
        <>
          {this.setInfoContent()}
          {this.setLinkContent()}
        </>
      )
      return content
    }
    return (
      <div className="career-portal-page-loading-component">Loading...</div>
    )
  }

  render() {
    const html = <>{this.setMainContent()}</>
    return html
  }
}
