import React from 'react';
import './tiggbee-contact-us.css';
// import footer_contact_us_background from '../images/contact-us-background.png';
import { Link } from "gatsby";

export class TiggbeeContactUs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const html = <>
            <div className={`contact-us-container${(this.props.isSmallContainer)?(' contact-us-small-container'):('')}`}
                style={{
                    backgroundImage: `url(/contact-us-background.png)`
                }}
            >
                <div className='contact-us-title'>{this.props.titleElement}</div>
                {
                    (this.props.isHideButton)?(
                        <></>
                    ):(
                        <Link className='contact-us-button' to='/contact-us-page'>Contact Us</Link>
                    )
                }
            </div>
        </>;
        return (
            html
        );
    }
}