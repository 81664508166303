import React from 'react';
import './banner-simple.css';

export class BannerSimple extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const html = <>
            <div className='banner-simple-container container-fluid footer-containter'>
                <div>{this.props.message}</div>
            </div>
        </>;
        return (
            html
        );
    }
}