export class BaseService {
  static stringIsNullOrWhitespace = (text) => {
    return !text || !text.trim()
  }

  static formatCurrency = (currency, locateString) => {
    locateString = locateString ? locateString : "en-US"
    const currencyString = currency.toLocaleString(locateString)
    return `${currencyString}`
  }

  static getVideoURL = (s3ObjectKey) => {
    const isUseCloudFront = true
    let url = ""
    if (isUseCloudFront) {
      url = `https://d3luynq40dfrpk.cloudfront.net${s3ObjectKey}`
    } else {
      url = `https://tiggbeeweb6b99bab7c47b48e7820368aded133811-dev.s3-us-west-2.amazonaws.com${s3ObjectKey}`
    }
    return url
  }

  static getHomepagePartnerScrollerDataURL = () => {
    const isUseCloudFront = false
    let url = ""
    const s3ObjectKey = "/public/homepage-partner-scroller/index.json"
    if (isUseCloudFront) {
      url = `https://d3luynq40dfrpk.cloudfront.net${s3ObjectKey}`
    } else {
      url = `https://tiggbeeweb6b99bab7c47b48e7820368aded133811-dev.s3-us-west-2.amazonaws.com${s3ObjectKey}`
    }
    return url
  }

  static getHomepagePartnerScrollerLogoURL = (fileName) => {
    const isUseCloudFront = false
    let url = ""
    const s3ObjectPrefix = "/public/homepage-partner-scroller/"
    if (isUseCloudFront) {
      url = `https://d3luynq40dfrpk.cloudfront.net${s3ObjectPrefix}${fileName}`
    } else {
      url = `https://tiggbeeweb6b99bab7c47b48e7820368aded133811-dev.s3-us-west-2.amazonaws.com${s3ObjectPrefix}${fileName}`
    }
    return url
  }
}
